<template>
  <mounting-portal v-if="isOpen" append :mount-to="mountPoint">
    <transition
      enter-active-class="transition-opacity ease-ease-out"
      leave-active-class="transition-opacity ease-ease-in"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="isOpen"
        class="modal"
        :class="classModal"
        role="dialog"
        :aria-hidden="isOpen ? 'false' : 'true'"
        aria-labelledby="modal-header"
        aria-describedby="modal-body"
        @keydown.esc="hideModal"
      >
        <div
          class="modal__backdrop"
          :class="classBackdrop"
          @click="hideModal"
        />

        <div
          id="modal-container"
          ref="container"
          class="modal__container"
          :class="classContainer"
        >
          <div id="modal-header" class="modal__header" :class="classHeader">
            <div>
              <slot name="header"> &nbsp; </slot>
            </div>

            <button
              class="modal__btn-close"
              :class="classBtnClose"
              aria-label="Close Modal"
              @click="hideModal"
            >
              <svg-icon name="close" class="svg-icon--xl" focusable="false" />
            </button>
          </div>

          <div id="modal-body" class="modal__body" :class="classBody">
            <slot />
          </div>

          <div v-if="hasFooterSlot" class="modal__footer" :class="classFooter">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </transition>
  </mounting-portal>
</template>

<script>
import * as focusTrap from 'focus-trap';

export default {
  name: 'Modal',
  props: {
    /**
     * Specifies if the modal is opened or not
     */
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    /**
     * Optional class for the modal
     */
    classModal: {
      type: String,
      default: '',
    },
    /**
     * Optional class for the backdrop
     */
    classBackdrop: {
      type: String,
      default: '',
    },
    /**
     * Optional class for the modal container
     */
    classContainer: {
      type: String,
      default: '',
    },
    /**
     * Optional class for the modal header
     */
    classHeader: {
      type: String,
      default: '',
    },
    /**
     * Optional class for the modal body
     */
    classBody: {
      type: String,
      default: '',
    },
    /**
     * Optional class for the modal footer
     */
    classFooter: {
      type: String,
      default: '',
    },
    /**
     * Optional class for the close button
     */
    classBtnClose: {
      type: String,
      default: '',
    },
    /**
     * Specifies where the modal is mounted
     */
    mountPoint: {
      type: String,
      default: 'body',
    },
    /**
     * Specifies the initial focus element. Defaults to the close button
     */
    focusElement: {
      type: String,
      default: '.modal__btn-close',
    },
    /**
     * Specifies if the focus trap should be enabled
     */
    enableFocusTrap: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      initiallyFocusedElement: null,
      focusTrap: null,
    };
  },

  computed: {
    hasFooterSlot() {
      return !!this.$slots['footer'];
    },
  },

  watch: {
    isOpen(opened) {
      if (opened) {
        this.saveLastActiveFocus();
        this.activateFocusTrap();
      } else {
        this.refocusLastActive();
        this.deactivateFocusTrap();
      }
    },
  },

  mounted() {
    window.addEventListener('keyup', this.handleKeyEvent);
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyEvent);
    this.hideModal(false);
  },

  methods: {
    hideModal(emitEvent = true) {
      if (emitEvent) {
        this.$emit('closeModal');
      }

      this.refocusLastActive();
      this.deactivateFocusTrap();
    },
    refocusLastActive() {
      if (this.initiallyFocusedElement instanceof HTMLElement) {
        this.initiallyFocusedElement.focus();
      }
    },
    saveLastActiveFocus() {
      this.initiallyFocusedElement = document.activeElement;
    },
    activateFocusTrap() {
      if (!this.enableFocusTrap) {
        return;
      }
      // We need next tick to be sure that the element is rendered
      this.$nextTick(() => {
        this.focusTrap = focusTrap.createFocusTrap(this.$refs.container, {
          initialFocus: this.focusElement,
        });
        this.focusTrap.activate();
      });
    },
    deactivateFocusTrap() {
      if (this.focusTrap != null) {
        this.focusTrap.deactivate();
      }
    },
    handleKeyEvent(event) {
      if (event.code === 'Escape' && this.isOpen) {
        this.hideModal();
      }
    },
  },
};
</script>
